<template>
  <gtris-modal :name="name" @close-modal="onCloseModal">
    <div class="gabia-layer" :style="{ 'min-width': modalWidth }">
      <div class="gabia-layer-header">
        <slot name="title"> 제목 </slot>
      </div>
      <div class="gabia-layer-body">
        <slot name="content">내용</slot>
      </div>
      <div class="gabia-layer-close" @click="closeModal"></div>
    </div>
  </gtris-modal>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  props: {
    name: {
      type: String,
      required: true,
    },
    modalWidth: {
      type: String,
      default: '500px',
    },
  },
  methods: {
    closeModal() {
      this.$console.log(this.name);
      this.$GabiaModal.hide(this.name);
      this.$emit('close-modal');
    },
    onCloseModal() {
      this.$emit('close-modal');
    },
  },
});
</script>

<style lang="scss" scoped>
.gabia-layer {
  border-radius: 4px;
  max-width: 800px;
  .gabia-layer-header {
    position: relative;
    text-align: start;
    padding: 15px 20px;
    background: #4d515a;
    color: #fdd768;
    font-size: 16px;
  }
  .gabia-layer-body {
    height: 100%;
    padding: 25px 22px 28px;
    //overflow-y: auto;
    font-size: 14px;
    border: 1px solid #ccc;
    background: #fff;
    white-space: normal;
    color: #666;
  }
  .gabia-layer-close {
    position: absolute;
    top: 8px;
    right: 8px;
    padding: 5px;
    cursor: pointer;
    &:after {
      content: '\f00d';
      font-family: 'Font Awesome 5 Pro';
      font-size: 20px;
      line-height: 1;
      color: #fff;
    }
  }
}

@media only screen and (min-width: 1024px) {
  .gabia-layer {
    box-shadow: 8px 8px 8px rgb(0 0 0 / 5%);
    .gabia-layer-header {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
    .gabia-layer-body {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
    .gabia-layer-close {
      top: 8px;
      right: 10px;
    }
  }
}

@include mq('tablet', 'max') {
  .gabia-layer {
    min-width: 100%;

    .gabia-layer-body {
      overflow: auto;
    }
  }
}
</style>
