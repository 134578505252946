import { instance } from '../instance';
import { AxiosPromise } from 'axios';
import { NimsGroup } from './types';
import { PriceInfo } from '@/api/goods/types';
import {
  ServiceInfo,
  AutoPayServiceInfo,
  CreateGroupOrder,
  MygabiaManager,
  ModifyPackage,
  ModifyGoodsOrder,
} from './types';
import { CreatedOrderInfoForNims, NimsSettingInfo, CreateTechOrderForNims } from '@/api/gabia-proxy/types';

const BASEURL = process.env.VUE_APP_GABIA_GOODS_PROXY_URI;

const goodsProxyAPI = instance.createWithoutInterceptors(BASEURL, {
  headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
});

/** 클라우드 신청 가능 불가 여부 체크 */
export function getUserGroupInfo(): AxiosPromise<{ service: NimsGroup[] }> {
  return goodsProxyAPI.get(`/service-api?uri=/v1/services/simple&data[carve_code]=nims`);
}

export function getGoodsPriceExpiredSpecUseProxy(params): AxiosPromise<PriceInfo | null> {
  return goodsProxyAPI.get('goods-api', {
    params,
  });
}

export function getAutoPayInfoUseProxy(params): AxiosPromise<AutoPayServiceInfo[] | null> {
  return goodsProxyAPI.get('gapi', {
    params,
  });
}

export function getMyGabiaServiceManagersProxy(params): AxiosPromise<{ data: MygabiaManager[]; owner_total: string }> {
  return goodsProxyAPI.get('gapi', {
    params,
  });
}

export function getPackagesSearch(params): AxiosPromise<{ data: ModifyPackage[] }> {
  return goodsProxyAPI.get('goods-api', {
    params,
  });
}

export function getServiceInfoUseProxy(params): AxiosPromise<ServiceInfo | null> {
  return goodsProxyAPI.get('service-api', {
    params,
  });
}

export function createNimsOrderProxy(params): AxiosPromise<CreatedOrderInfoForNims> {
  return goodsProxyAPI.post('order-api', params);
}

export function createNimsTechOrderProxy(params): AxiosPromise<CreateTechOrderForNims> {
  return goodsProxyAPI.post('order-api', params);
}

export function createNimGroupProxy(params): AxiosPromise<CreateGroupOrder> {
  return goodsProxyAPI.post('order-api', params);
}

export function createNimGroupCompleteProxy(params): AxiosPromise<CreateGroupOrder> {
  return goodsProxyAPI.post('order-api', params);
}

export function modifyProductForNims(params): AxiosPromise<ModifyGoodsOrder> {
  return goodsProxyAPI.post('order-api', params);
}

export function getNimsOrderInfoProxy(params): AxiosPromise<NimsSettingInfo> {
  return goodsProxyAPI.get('order-api', { params });
}

export function deleteNimsOrderProxy(params): AxiosPromise<any> {
  return goodsProxyAPI.delete('gapi', { data: params });
}
