
import Vue from 'vue';
import InfoModal from '@/components/common/InfoModal/InfoModal.vue';

export default Vue.extend({
  name: 'Step01Modals',
  components: { InfoModal },
  data() {
    return {
      idcTermsSrc: `${process.env.VUE_APP_POLICY_URL}/idc/?page=application`,
    };
  },
  computed: {},
  mounted() {
    const iframe = document.createElement('iframe');
    iframe.src = this.idcTermsSrc;
    iframe.width = '800';
    iframe.height = '800';
    iframe.frameBorder = '0';

    const modalContent = document.querySelector('#nimsTermsModal');
    modalContent?.appendChild(iframe);
  },

  methods: {},
});
